import styled from 'styled-components';
import { breakpoints } from '../../../constants/breakpoints';

export const Title = styled.h2`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    color: #ffffff;
    max-width: 330px;
  }

  @media ${breakpoints.laptop} {
    max-width: 730px;
    font-size: 48px;
  }
`;

export const LastBlockWrapper = styled.div`
  @media${breakpoints.mobile} {
    & {
      width: 100%;
      height: 500px;
      padding: 190px 15px 82px;
    }

    ${Title} {
      margin-bottom: 68px;
    }
  }

  @media${breakpoints.tablet} {
    & {
      height: 600px;
      padding: 190px 24px 82px;
    }
  }

  @media${breakpoints.laptop} {
    & {
      height: 657px;
      padding: 266px 32px 0;
    }

    ${Title} {
      margin-bottom: 60px;
    }
  }

  @media${breakpoints.desktop} {
    & {
      padding: 266px 52px 0;
      max-width: 1440px;
      margin: 0 auto;
    }
  }
`;
