import React from 'react';
import Button from '@material-ui/core/Button';

import { LastBlockWrapper, Title } from './styles';

const LastBlock = ({ title, button, link }) => (
  <LastBlockWrapper>
    <Title>{title}</Title>
    <a href={link} title={button} className="link">
      <Button variant="contained" color="primary">
        {button}
      </Button>
    </a>
  </LastBlockWrapper>
);

export default LastBlock;
