import styled from 'styled-components';
import DatePickerInput from 'react-day-picker/DayPickerInput';
import { breakpoints } from '../../../constants/breakpoints';

export const StyledDayPiker = styled(DatePickerInput)`
  @media ${breakpoints.mobile} {
    & input {
      border: none;
      background-color: red;
      padding: 0;
      color: ${(props) => (props.error ? 'red' : 'inherit')};
    }

    .DayPicker-wrapper {
      z-index: 999;
    }

    .DayPicker-Weekdays {
      margin-bottom: 16px;
    }

    .DayPicker-Weekday {
      color: #8f9bb3;
    }

    .DayPicker-Day {
      padding: 0;
      color: #303133;
      font-size: 16px;
      width: 36px;
      height: 36px;
    }

    .DayPicker-Day--selected {
      background: rgba(6, 107, 214, 0.1);
    }

    .DayPicker-Day .DayPicker-Day--disabled {
      opacity: 0.5;
      outline: none;
    }

    .DayPicker-Day--end,
    .DayPicker-Day--start {
      background: #066bd6;
      border-radius: 50%;
      color: #ffffff;
    }
  }
`;
