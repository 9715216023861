import styled from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const CustomAutocomplete = styled(Autocomplete)`
  & {
    border: none;
    width: 110px;
  }

  .popup-indicator {
    width: 24px;
    height: 24px;
  }

  .MuiAutocomplete-popupIndicator:hover {
    background: none;
  }
`;
