import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Form, Formik } from 'formik';
import { add, addDays, format, getMinutes, isAfter, set } from 'date-fns';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
// import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AddressSuggest from '../../ui-kit/addressSuggest';
import DayPickerInput from '../../ui-kit/dayPickerInput';
import AppLinksBlock from '../../ui-kit/appLinks';
import SearchIcon from '../../../assets/icons/search.svg';
import timeSlots from '../../../constants/timeSlots';
import { GET_SUGGESTS_URL } from '../../../constants/endpoints';
import {
  defaultEndRent,
  defaultRentPeriod,
  defaultStartRent,
  formatDateISO,
  getRentInitialValues,
  joinDayAndHours,
} from '../../../utils/date';
import { track } from '../../../seo';

import { StyledError } from '../../../styledComponents';
import { Description, FormWrapper, PickerWrapper, SearchWidgetWrapper, Title } from './styles';
import theme from '../../../theme';
import { useMediaQuery } from '@material-ui/core';

export const getStartDate = () => {
	const now = new Date()
	const isNowMoreThanThirtyMins = getMinutes(now) >= 30

	return set(now, isNowMoreThanThirtyMins ? { hours: now.getHours() + 1, minutes: 0 } : { minutes: 30 })
}

const initialOptions = [
  {
    address: 'Краснодар',
    location: {
      bounds: '39.21432909410287,45.262907255865954,38.82733569599969,44.736904200467755',
    },
  },
  {
    address: 'Москва',
    location: {
      bounds: '37.923687315056576,56.00400954445204,37.260010160539174,55.2840959491822',
    },
  },
  {
    address: 'Санкт-Петербург',
    location: {
      bounds: '30.67460357537279,60.145927970035416,30.091552606178553,59.583274795054635',
    },
  },
];
// TODO add geoposition success and error functions
export default ({ filters = '', title, text, seo, initialCity }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [inputValue, setInputValue] = useState('');
  
  const [value, setValue] = useState(initialOptions[0]);
  const [options, setOptions] = useState(initialOptions);

  const defaultStartDate = defaultStartRent();
  const defaultEndDate = defaultEndRent(defaultStartDate);

  useEffect(() => {
    if (Object.values(initialCity).length) setValue(initialCity);
  }, [initialCity]);

  const getSuggests = async (inputValue) => {
    try {
      const response = await axios.get(GET_SUGGESTS_URL, { params: { q: inputValue } });
      const newOptions = response.data.data.slice(0, 5);
      setOptions(newOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeAddress = (newValue, setFieldValue) => {
    const { address, location } = newValue;
    setFieldValue('bounds', location.bounds);
    setFieldValue('address', address);
    setValue(newValue);
  };

  return (
    <SearchWidgetWrapper>
      <Title>{title}</Title>
      <Description>{text}</Description>
      {/* <CityIcon className="decoration" /> */}
      <Formik
        initialValues={{
          bounds: initialOptions[0].location.bounds,
          address: initialOptions[0].address
        }}
        onSubmit={({ dateStart, dateEnd, bounds, address }) => {
          if (isMobile) {
            if (seo) track(seo)
            const queries = `bounds=${bounds}&address=${address}${filters}`;
            const url = `/app/date-picker?${queries}`;
            window.location = url
          } else {
            const dateStartQueries = encodeURIComponent(dateStart);
            const dateEndQueries = encodeURIComponent(dateEnd);
            if (seo) {
              track(seo);
            }
            const queries = `bounds=${bounds}&address=${address}&dateStart=${dateStartQueries}&dateEnd=${dateEndQueries}${filters}`;
            const url = `/app/search?${queries}`;
            window.location = url;
          }
        }}
        validate={(values) => {
          const errors = {};
          if (!values.bounds) {
            errors.bounds = 'Введите город';
          }
          if (!values.dateStart && !isMobile) {
            errors.dateStart = 'Введите дату начала аренды';
          }
          if (!values.dateEnd && !isMobile) {
            errors.dateEnd = 'Введите дату конца аренды';
          }
          return errors;
        }}
      >
        {({
          values: { dateStart = defaultStartDate, dateEnd = defaultEndDate },
          submitForm,
          setFieldValue,
          errors,
          touched,
        }) => {
          const {
            dayStart,
            dayEnd,
            dayISOStart,
            timeStart,
            dayISOEnd,
            timeEnd,
          } = getRentInitialValues({
            dateStart,
            dateEnd,
          });
          return (
            <Form className="form">
              <FormWrapper>
                <div className="location-wrap">
                  {/* <span className="label">Выберите город</span> */}
                  <AddressSuggest
                    id="city-suggest"
                    value={value}
                    options={options}
                    handleChange={(newValue) => handleChangeAddress(newValue, setFieldValue)}
                    handleInputChange={setInputValue}
                    className="suggest"
                    inputClassNamess={`autocomplete${errors.bounds ? ' autocomplete-error' : ''}`}
                    placeholder="Введите город"
                  />
                  {errors.bounds && touched.bounds ? (
                    <StyledError>{errors.bounds}</StyledError>
                  ) : null}
                </div>
                {
                  !isMobile && <>  
                    <div className="date-wrap">
                      {/* <span className="label">Выберите дату и время получения</span> */}
                      <PickerWrapper className={errors.dateStart ? 'error' : ''}>
                        <DayPickerInput
                          // value={dayStart}
                          format="dd MMMM yyyy"
                          placeholder="Начало аренды"
                          dayPickerProps={{
                            selectedDays: [dayStart, { from: dayStart, to: dayEnd }],
                            disabledDays: { before: new Date() },
                            month: dayStart,
                            fromMonth: new Date(),
                            modifiers: { start: dayStart, end: dayEnd },
                            numberOfMonths: 1,
                          }}
                          onDayChange={(day) => {
                            const newDay = formatDateISO(day);
                            const nextDay = formatDateISO(addDays(day, defaultRentPeriod));
                            if (isAfter(day, dayEnd)) {
                              setFieldValue('dateEnd', joinDayAndHours(nextDay, timeEnd));
                            }
                            setFieldValue('dateStart', joinDayAndHours(newDay, timeStart));
                          }}
                          className="picker-wrap"
                        />
                        <Select
                          id="startHour"
                          native
                          className="hourStart date-hours"
                          value={timeStart}
                          input={<Input className="autocomplete" />}
                          onChange={({ target: { value } }) =>
                            setFieldValue('dateStart', joinDayAndHours(dayISOStart, value))
                          }
                        >
                          {timeSlots.map((time) => (
                            <option key={`start-${time}`} value={time}>
                              {time}
                            </option>
                          ))}
                        </Select>
                      </PickerWrapper>
                    </div>
                    <div className="date-wrap">
                      {/* <span className="label">Выберите дату и время возврата</span> */}
                      <PickerWrapper className={errors.dateEnd ? 'error' : ''}>
                        <DayPickerInput
                          // value={dayEnd}
                          format="dd MMMM yyyy"
                          placeholder="Конец аренды"
                          dayPickerProps={{
                            selectedDays: [dayStart, { from: dayStart, to: dayEnd }],
                            // TODO add correct after (with hours)
                            disabledDays: { before: dayStart },
                            modifiers: { start: dayStart, end: dayEnd },
                            month: dayStart,
                            fromMonth: dayStart,
                            numberOfMonths: 1,
                          }}
                          onDayChange={(day) => {
                            const newDay = formatDateISO(day);
                            setFieldValue('dateEnd', joinDayAndHours(newDay, timeEnd));
                          }}
                          className="picker-wrap"
                        />
                        <Select
                          id="endHour"
                          native
                          className="hourEnd date-hours"
                          value={timeEnd}
                          input={<Input className="autocomplete" />}
                          onChange={({ target: { value } }) =>
                            setFieldValue('dateEnd', joinDayAndHours(dayISOEnd, value))
                          }
                        >
                          {timeSlots.map((time) => (
                            <option key={`end-${time}`} value={time}>
                              {time}
                            </option>
                          ))}
                        </Select>
                      </PickerWrapper>
                    </div>
                  </> 
                }
                <Button
                  startIcon={<SearchIcon className="icon" />}
                  variant="contained"
                  color="secondary"
                  onClick={submitForm}
                  className="btn"
                >
                  Найти свободные авто
                </Button>
                {/* {errors.dateStart ? (
                    <StyledError className="error ">{errors.dateStart}</StyledError>
                  ) : null}
                  {errors.dateEnd ? (
                    <StyledError className="error">{errors.dateEnd}</StyledError>
                  ) : null} */}
              </FormWrapper>
            </Form>
          );
        }}
      </Formik>
      {/* <StyledLink block href="/car-rental-income" title="Начать зарабатывать на своем автомобиле">
        <VerifiedUserIcon className="icon-shield" />
        Начать зарабатывать на своем автомобиле
      </StyledLink> */}
      <AppLinksBlock />

      {/* <InsuranceIngostrah className="insurance-logo" />
      <SmallText>страховой партнер маркетплейса</SmallText> */}
    </SearchWidgetWrapper>
  );
};
