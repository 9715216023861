import React, { useEffect, useMemo, useState } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isBrowser } from '../utils/isBrowser';
import Layout from '../components/layout';
import Partners from '../components/partners';
import ReviewBlock from '../components/reviewBlock';
import AppLinksBlock from '../components/homepage/appLinksBlock';
import InsuranceBlock from '../components/safety/insurance';
import LastBlock from '../components/homepage/lastBlock';
import SEO from '../components/seo';
import LastImage from '../assets/images/time-to-book.webp';
import MobileLastImage from '../assets/images/time-to-book-mob.webp';
import { serviceAdvantagesContent } from '../assets/data/service-advantages-content';
import { defaultEndRent, defaultStartRent } from '../utils/date';

import { BackgroundWrapper, Colored, Company, Title } from '../styledComponents';
import GeographyBlock from '../components/homepage/geographyBlock';
import SearchWidget from '../components/homepage/searchWidget';
import { availableCities } from '../components/homepage/searchWidget/WidgetWithout2GIS';

const sharingTitle = <>Зарабатывайте на сдаче автомобиля в аренду на маркетплейсе Getarent</>;

const lastBlockTitle = 'Пора забронировать машину';

const lastBlockButton = 'Показать автомобили';

const title = (
  <>
    Поиск машины <Company>в аренду от 1 дня</Company>
  </>
);
const text = 'Выберите по ценам, настоящим фото и отзывам';

export default ({ location }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const initialQueryCity = searchParams.get('city');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isBrowser]);

  const defaultStartDate = defaultStartRent();
  const defaultEndDate = defaultEndRent(defaultStartDate);

  const dateStartQueries = encodeURIComponent(defaultStartDate);
  const dateEndQueries = encodeURIComponent(defaultEndDate);

  const query = `/app/search?dateStart=${dateStartQueries}&dateEnd=${dateEndQueries}&address=Выбранная+область+карты&bounds=158.8157939009998%2C88.72115576393806%2C0.6126688269558711%2C-86.92034111874895&location=&boundsTrigger=TOUCH`;

  const [initialCity, setInitialCity] = useState({});

  useEffect(() => {
    const foundCity = availableCities.find(
      (item) => initialQueryCity?.toLowerCase() === item.address?.toLowerCase(),
    );
    if (foundCity) {
      setInitialCity(foundCity);
    }
  }, [initialQueryCity]);

  return (
    <Layout>
      <SEO
        title="Getarent - сервис поиска и бронирования автомобилей для аренды у местных жителей"
        description="Сервис Getarent (Гетарент) поможет быстро найти и забронировать автомобиль для посуточной аренды у местного жителя без залогов с включенным страхованием для поездок по городу, на природу или в дальнее путешествие"
      />
      <BackgroundWrapper marginBottom>
        <SearchWidget title={title} text={text} initialCity={initialCity} />
      </BackgroundWrapper>
      <BackgroundWrapper>
        <GeographyBlock
          onClick={(cityId) => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            setInitialCity(availableCities.find((item) => item.key === cityId));
          }}
        />
      </BackgroundWrapper>
      {/* <BackgroundWrapper marginBottom>
        <WhoWeAreBlock />
      </BackgroundWrapper> */}
      <BackgroundWrapper marginBottom>
        <ReviewBlock titleText="Отзывы о Getarent" withoutMarginBottom withoutMarginTop LTR />
      </BackgroundWrapper>
      <BackgroundWrapper marginBottom>
        <InsuranceBlock
          title={
            <Title>
              <Colored color="#066BD6">Удобно</Colored>, как в каршеринге
            </Title>
          }
          content={serviceAdvantagesContent}
          LTR
        />
      </BackgroundWrapper>
      <BackgroundWrapper marginBottom>
        <AppLinksBlock title="Установите мобильное приложение, там удобнее" />
      </BackgroundWrapper>
      {/*    <BackgroundWrapper>
        <Faq
          content={faqContent}
          link="https://help.getarent.ru?utm_source=car-rental-income&utm_medium=pereiti-centr-pomoschi"
          title="Остались вопросы?"
          text="Найдите ответы на другие вопросы и узнайте больше о возможностях на сервисе"
        />
      </BackgroundWrapper> */}
      <BackgroundWrapper>
        <Partners withoutTopMargin LTR />
      </BackgroundWrapper>
      <BackgroundWrapper
        background={isMobile ? `url(${MobileLastImage}) center` : `url(${LastImage}) center`}
      >
        <LastBlock title={lastBlockTitle} button={lastBlockButton} link={query} />
      </BackgroundWrapper>
    </Layout>
  );
};
