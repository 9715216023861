import styled from 'styled-components';
import { Title } from '../../../styledComponents';
import { breakpoints } from '../../../constants/breakpoints';

export const GeographyText = styled.p`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 24px;
  }
`;

export const GeographyBlockWrapper = styled.div`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      box-sizing: border-box;
      padding: 0 15px 60px;
      max-width: 1440px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 85px;
    }

    ${Title} {
      margin-bottom: 40px;
      line-height: 140%;
    }

    ${GeographyText} {
      margin-bottom: 24px;
    }
  }

  @media ${breakpoints.tablet} {
    & {
      // padding: 0 24px 60px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 85px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      padding: 0 36px 100px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      top: 122px;
    }

    ${Title} {
      margin-bottom: 60px;
    }

    ${GeographyText} {
      margin-bottom: 40px;
    }
  }

  @media ${breakpoints.desktop} {
    & {
      padding: 0 50px 100px;
    }
  }
`;

export const CardTitle = styled.h3`
  @media ${breakpoints.mobile} {
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #222222;
  }

  @media ${breakpoints.laptop} {
    font-size: 18px;
  }
`;

export const CardTextContainer = styled.div`
  @media ${breakpoints.mobile} {
    & {
      position: absolute;
      right: 8px;
      bottom: 8px;
      display: flex;
      align-items: center;
      padding: 6px 8px;
      box-shadow: 0px 4px 16px rgba(36, 93, 150, 0.15);
      border-radius: 4px;
      background: #ffffff;
    }

    .card-icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      right: 20px;
      bottom: 20px;
    }
  }
`;

export const CardText = styled.p`
  @media ${breakpoints.mobile} {
    font-weight: 600;
    font-size: 10px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #222222;
  }
`;

export const CardImage = styled.div`
  @media ${breakpoints.mobile} {
    position: relative;
    width: 100%;
    height: 248px;
    background: ${(props) => `url(${props.src}) center no-repeat`};
    background-size: cover;
    border-radius: 4px;
  }

  @media ${breakpoints.laptop} {
    width: 100%;
    height: 225px;
  }
`;

export const CardWrapper = styled.div`
  @media ${breakpoints.mobile} {
    width: 240px;

    ${CardTitle} {
      margin: 24px 0;
    }

    .link {
      display: flex;
      height: 40px;
      width: 100%;
    }

    .btn:hover {
      background-color: #000;
      color: #fff;
    }

    .btn {
      width: 100%;
      padding: 0;
      height: 100%;
    }
  }

  @media ${breakpoints.laptop} {
    & {
      width: 360px;
    }

    .link {
      display: flex;
      height: 40px;
      width: 60%;
    }
  }
`;

export const DialogImage = styled.div`
  @media ${breakpoints.mobile} {
    width: 100%;
    height: 248px;
    background: ${(props) => `url(${props.mobileImage}) center no-repeat`};
    background-size: cover;
    margin-top: 40px;
    border-radius: 4px;
  }

  @media ${breakpoints.laptop} {
    height: 349px;
    background: ${(props) => `url(${props.laptopImage}) center no-repeat`};
    background-size: contain;
  }
`;
