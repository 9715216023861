import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '../autocomplete';

const defaultFilter = (options) => options;

const AddressSuggest = ({
  handleInputChange,
  handleChange,
  options,
  placeholder,
  value,
  id,
  className,
  inputClassNamess,
  filter,
  ...props
}) => {
  return (
    <Autocomplete
      id={id}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.address || option.name;
      }}
      getOptionSelected={(option) => option.address || option.name}
      popupIcon={null}
      options={options}
      autoComplete
      filterOptions={filter || defaultFilter}
      value={value}
      onChange={(event, newValue) => {
        const { address, location, name } = newValue;
        const newAddress = address || name;
        handleChange({ address: newAddress, location });
      }}
      onInputChange={(event, newInputValue) => {
        handleInputChange(newInputValue);
      }}
      disablePortal={true}
      renderInput={(params) => (
        <TextField {...params} placeholder={placeholder} className={inputClassNamess} />
      )}
      className={className}
      {...props}
    />
  );
};

export default AddressSuggest;
