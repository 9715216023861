import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CustomAutocomplete } from './styles';

export const Autocomplete = (props) => (
  <CustomAutocomplete
    noOptionsText={<span>нет вариантов</span>}
    closeText="очистить"
    popupIcon={<ExpandMoreIcon className="popup-indicator" />}
    disableClearable
    {...props}
  />
);
