import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GeographyCard from './geographyCard';
import SwiperComponent from '../../ui-kit/swiper';
import MoscowImage from '../../../assets/images/moscow_geography.webp';
import StPetersburgImage from '../../../assets/images/saint_p_geography.webp';
// import KazanImage from '../../../assets/images/87.jpg';
import KrasnodarImage from '../../../assets/images/krasnodar_geography.webp';

import { Company, Title } from '../../../styledComponents';
import { GeographyBlockWrapper } from './styles';

const titleText = (
  <>
    География <Company>Getarent</Company>
  </>
);

// const text = 'Регистрируем автомобили в лучших туристических местах России';

const content = [
  {
    image: KrasnodarImage,
    title: 'Краснодар',
    btnText: 'Показать машины',
    key: 'krasnodar',
  },
  {
    image: MoscowImage,
    title: 'Москва',
    btnText: 'Показать машины',
    key: 'moscow',
  },
  {
    image: StPetersburgImage,
    title: 'Санкт-Петербург',
    btnText: 'Показать машины',
    key: 'stPetersburg',
  },
  // {
  //   image: SochiImage,
  //   title: 'Сочи',
  //   btnText: 'Показать машины',
  //   key: 'sochi',
  // },
  // {
  //   image: AnapaImage,
  //   title: 'Анапа',
  //   btnText: 'Показать машины',
  //   key: 'anapa',
  // },
  // {
  //   image: KaliningradImage,
  //   title: 'Калининград',
  //   btnText: 'Показать машины',
  //   key: 'kaliningrad',
  // },
  // {
  //   image: EkaterinburgImage,
  //   title: 'Екатеринбург',
  //   btnText: 'Показать машины',
  //   key: 'ekaterinburg',
  // },
  // {
  //   image: GelendjikImage,
  //   title: 'Геленджик',
  //   btnText: 'Показать машины',
  //   key: 'gelendjik',
  // },
  // {
  //   image: NovorossiyskImage,
  //   title: 'Новороссийск',
  //   btnText: 'Показать машины',
  //   key: 'novorossiysk',
  // },
];

const GeographyBlock = ({ onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <GeographyBlockWrapper>
      <Title>{titleText}</Title>
      {/* <GeographyText>{text}</GeographyText> */}
      <SwiperComponent name="host-swiper" options={{ spaceBetween: isMobile ? 36 : 30 }}>
        {content.map(({ image, title, btnText, key }) => (
          <GeographyCard
            onClick={onClick}
            className="swiper-slide"
            image={image}
            title={title}
            btnText={btnText}
            id={key}
            key={key}
          />
        ))}
      </SwiperComponent>
    </GeographyBlockWrapper>
  );
};

export default GeographyBlock;
