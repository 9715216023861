import React, { useRef } from 'react';
import styled from 'styled-components';
import DatePickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import ruLocale from 'date-fns/locale/ru';
import localizationUtils from '../../../assets/locale';
import arrow from '../../../assets/icons/arrow.svg';
import Calendar from '../../../assets/images/calendar.png';
import { breakpoints } from '../../../constants/breakpoints';
import 'react-day-picker/lib/style.css';

import { StyledDayPiker } from './styles';

function parseDate(str, format) {
  // TODO: add other locales if needed (3rd parameter is locale)
  const parsed = dateFnsParse(str, format, new Date(), { locale: ruLocale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format) {
  // TODO: add other locales if needed (3rd parameter is locale)
  return dateFnsFormat(date, format, { locale: ruLocale });
}
const { classNames } = DatePickerInput.defaultProps;

const CustomOverlay = ({ classNames, selectedDay, children, close, ...props }) => (
  <div className={classNames.overlayWrapper} {...props}>
    <div onClick={close} className="click-catcher" />
    <div className={classNames.overlay}>{children}</div>
  </div>
);

const DayPickerInputWrapper = ({
  className = '',
  key,
  format,
  dayPickerProps,
  inputProps,
  placeholder = '',
  ...props
}) => {
  const ref = useRef(null);

  const handleCloseOverlay = () => {
    /* eslint-disable no-unused-expressions */
    ref?.current?.setState({ showOverlay: false });
  };
  return (
    <StyledDayPiker
      {...props}
      placeholder={placeholder}
      format={format}
      overlayComponent={(_props) => <CustomOverlay {..._props} close={handleCloseOverlay} />}
      inputProps={{ ...inputProps, readOnly: true }}
      component={(props) => <input {...props} />}
      formatDate={formatDate}
      parseDate={parseDate}
      dayPickerProps={{
        locale: 'ru',
        localeUtils: localizationUtils,
        ...dayPickerProps,
      }}
      classNames={{
        ...classNames,
        container: `${classNames.container} ${className}`,
      }}
      ref={ref}
    />
  );
};

const DayPickerInput = styled(DayPickerInputWrapper)`
  @media ${breakpoints.mobile} {
    & {
      width: 100%;
      height: 32px;
      color: #5d5d5d;
      border-right: 1px solid #8f9bb3;
      margin-right: 8px;
    }

    && input,
    && input:hover,
    && input:focus {
      border: none;
      background: url(${Calendar}) no-repeat;
      background-position-x: 0;
      background-position-y: 7px;
      background-color: #fff;
      outline: none;
      width: 100%;
      height: 100%;
      padding-left: 30px;
      color: ${(props) => (props.error ? 'red' : '#303133')};
      font-size: 16px;
      font-family: Open Sans;
    }

    .DayPickerInput-Overlay {
      z-index: 999;
      width: 285px;
      left: -17px;
    }

    .DayPicker-Weekdays {
      margin-bottom: 16px;
    }

    .DayPicker-Weekday {
      color: #8f9bb3;
    }

    .DayPicker-Day {
      padding: 0;
      margin-bottom: 16px;
      color: #303133;
      font-size: 16px;
      width: 36px;
      height: 36px;
    }

    .DayPicker-Week .DayPicker-Day--disabled {
      opacity: 0.5;
      outline: none;
    }

    .DayPicker-Week .DayPicker-Day--selected {
      background: rgba(6, 107, 214, 0.1);
      color: #303133;
      border-radius: 0;
    }

    .DayPicker-Week .DayPicker-Day--end.DayPicker-Day--selected,
    .DayPicker-Week .DayPicker-Day--start.DayPicker-Day--selected {
      background: #066bd6;
      border-radius: 50%;
      color: #ffffff;
    }

    .DayPicker-NavButton--next {
      background: ${`url(${arrow}) no-repeat`};
    }

    .DayPicker-NavButton--prev {
      background: ${`url(${arrow}) no-repeat`};
    }

    .DayPicker-Body .DayPicker-Week .DayPicker-Day--outside {
      background: transparent;
    }

    .click-catcher {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 998;
    }
  }
`;

export default DayPickerInput;
