import React from 'react';
import Button from '@material-ui/core/Button';

import { endRent, startRentWithDelay } from '../../../utils/date';
import { CardImage, CardTitle, CardWrapper } from './styles';

const links = {
  moscow:
    '/app/search?bounds=37.923687315056576%2C56.00400954445204%2C37.260010160539174%2C55.2840959491822&address=Москва',
  sochi:
    '/app/search?bounds=40.37687715806164%2C44.06036955587499%2C39.486315109227895%2C42.91462870099871&address=Сочи',
  stPetersburg:
    '/app/search?bounds=30.67460357537279%2C60.145927970035416%2C30.091552606178553%2C59.583274795054635&address=Санкт-Петербург',
  krasnodar:
    '/app/search?bounds=39.21432909410287%2C45.262907255865954%2C38.82733569599969%2C44.736904200467755&address=Краснодар',
  anapa: '/app/search?bounds=37.403413%2C44.991547%2C37.21852%2C44.823405&address=Анапа',
  kaliningrad:
    '/app/search?bounds=21.043638582301714%2C55.36229173523583%2C19.86078141773221%2C54.04873070830888&address=Калининград',
  chelyabinsk:
    '/app/search?bounds=61.79006920244895%2C55.496076062452225%2C61.03018186531283%2C54.65993651194508&address=Челябинск',
  ekaterinburg:
    '/app/search?bounds=60.729171736099964%2C56.96732138051319%2C60.4626937555677%2C56.68704813173861&address=Екатеринбург',
  gelendjik:
    '/app/search?bounds=38.15300296455196%2C44.65192778696307%2C37.99575962036885%2C44.43650705843386&address=Геленджик',
  novorossiysk:
    '/app/search?bounds=37.832529127134116%2C44.77999608918054%2C37.712376289774426%2C44.615823789776776&address=Новороссийск',
};

const RENT_DELAY = 30;
const RENT_DURATION = 14;

const GeographyCard = ({ image, title, btnText, className, id, onClick }) => {
  const dateStart = startRentWithDelay(RENT_DELAY);
  const dateEnd = endRent(dateStart, RENT_DURATION);
  const cityLink = `${links[id]}&dateStart=${encodeURIComponent(
    dateStart,
  )}&dateEnd=${encodeURIComponent(dateEnd)}`;

  return (
    <CardWrapper className={className}>
      <CardImage src={image}>
        {/* <CardTextContainer>
          <CarIcon className="card-icon" />
          <CardText>{text}</CardText>
        </CardTextContainer> */}
      </CardImage>
      <CardTitle>{title}</CardTitle>
      {onClick ? (
        <Button onClick={() => onClick(id)} variant="outlined" className="btn link">
          {btnText}
        </Button>
      ) : (
        <a href={cityLink} title={id} className="link">
          <Button variant="outlined" className="btn">
            {btnText}
          </Button>
        </a>
      )}
    </CardWrapper>
  );
};

export default GeographyCard;
